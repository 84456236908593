import { gql, useLazyQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import {
  ActionFunction,
  json,
  LoaderFunction,
  RouteMatch,
  useActionData,
  useLoaderData,
} from 'react-router-dom'

import { Customer, CustomerFile } from '../../../types'
import { baseUrl, graphql } from '../api'
import Breadcrumb from '../components/Breadcrumb'
import ErrorBoundary from '../components/ErrorBoundary'
import FileBrowser from '../components/FileBrowser'
import Separator from '../components/Separator'
import { dedupe } from '../utils/dedupe'

import { id } from './Authenticate'

export const path = '/arquivos/*'

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const keys = formData.get('keys')?.toString()
  const customerId = formData.get('customerId')?.toString()

  if (keys && customerId) {
    const response = await graphql.mutate({
      mutation: gql`
        mutation ($customerId: String!, $keys: [String!]!) {
          createFileBatch(customerId: $customerId, keys: $keys) {
            id
          }
        }
      `,
      variables: {
        customerId,
        keys: keys.split(','),
      },
      errorPolicy: 'all',
    })

    return json(response.data)
  }

  return json({})
}

export const loader: LoaderFunction = async ({ params }) => {
  const response = await graphql.query({
    query: gql`
      query ($prefix: String!) {
        me {
          role
          customer {
            id
            name
          }
        }
        files(prefix: $prefix) {
          key
          name
          type
          deletedAt
        }
      }
    `,
    variables: {
      prefix: params['*'] ? `${params['*']}/` : '',
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  return json(response.data)
}

export const handle = {
  breadcrumb: ({ params }: RouteMatch) => [
    <Breadcrumb key={id} to='/arquivos'>
      Arquivos
    </Breadcrumb>,
    <Separator key='separator' />,
    ...(params['*']
      ? params['*'].split('/').flatMap((prefix, index, array) => [
          <Breadcrumb
            key={`${id}_${index}`}
            to={`/arquivos/${array
              .slice(0, array.indexOf(prefix) + 1)
              .join('/')}`}
          >
            {prefix}
          </Breadcrumb>,
          index < array.length - 1 ? (
            <Separator key={`${index}_separator`} />
          ) : null,
        ])
      : []),
  ],
  title: ({ data }: { data: { me: { customer: Customer } } }) =>
    data.me?.customer?.name,
}

function Component() {
  const loaderData = useLoaderData() as {
    files: CustomerFile[]
    me: { customer: { id: string } }
  }
  const actionData = useActionData() as { createFileBatch: { id: string } }
  const [fileBatchId, setFileBatchId] = useState<string | null>(null)
  const [, { data, startPolling, stopPolling }] = useLazyQuery(
    gql`
      query ($id: String!) {
        fileBatch(id: $id) {
          id
          status
          name
        }
      }
    `,
    { variables: { id: fileBatchId } },
  )
  const items = loaderData
    ? dedupe<{
        key: string
        name: string
        type: 'FILE' | 'FOLDER'
      }>(
        loaderData.files.reduce(
          (acc, item) => {
            if (!item.name || item.deletedAt) return acc
            return [
              ...acc,
              {
                name: item.name,
                type: item.type,
                key: item.key,
              },
            ]
          },
          [] as {
            key: string
            name: string
            type: 'FILE' | 'FOLDER'
          }[],
        ),
        'name',
      )
    : []

  let toastId: string
  useEffect(() => {
    if (actionData?.createFileBatch?.id) {
      setFileBatchId(actionData?.createFileBatch?.id)
      startPolling(2500)
      toastId = toast.loading('Preparando os arquivos')
    }
  }, [actionData?.createFileBatch?.id, startPolling])

  useEffect(() => {
    if (data?.fileBatch?.status !== 'PENDING') {
      stopPolling()
      toast.dismiss(toastId)
      if (data?.fileBatch?.status === 'ERROR') {
        toast.error(
          'Ocorreu um erro ao preparar seus arquivos. Tente novamente.',
        )
      }
      if (data?.fileBatch?.status === 'READY') {
        toast.success('Seus arquivos estão prontos para baixar')
        const link = document.createElement('a')
        link.download = data.fileBatch.name
        link.href = `${baseUrl}/file/zip/${data.fileBatch.name}`
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }, [data?.fileBatch?.status, stopPolling])

  return (
    <div style={style.div}>
      <FileBrowser
        items={items}
        withActions={false}
        customerId={loaderData.me.customer.id}
      />
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const errorElement = <ErrorBoundary />

export const element = <Component />
